import {uuidV4} from '../util/utils'
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js'

export enum Color {
  GREEN,
  RED,
  YELLOW,
  BLUE,
}

export const cardColorMap = new Map([
  [Color.GREEN, {
    color: '#00aa00',
    name: 'Green',
  }],
  [Color.RED, {
    color: '#ff5555',
    name: 'Red',
  }],
  [Color.YELLOW, {
    color: '#ffaa00',
    name: 'Yellow',
  }],
  [Color.BLUE, {
    color: '#5555ff',
    name: 'Blue',
  }],
])

export enum CardFunction {
  WILD,
  WILD_DRAW_4,
  REVERSE,
  SKIP,
  DRAW_2,
}

export class Card {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string = ''

  @JsonProperty() @JsonClassType({type: () => [Number]})
  color: Color | null = null

  @JsonProperty() @JsonClassType({type: () => [Number]})
  number: number | null = null

  @JsonProperty() @JsonClassType({type: () => [Number]})
  function: CardFunction | null = null

  constructor(color: Color | null, number: number | null, fn: CardFunction | null) {
    this.color = color
    this.number = number
    this.function = fn
    this.id = uuidV4()
  }

  isNumberCard(): boolean {
    return typeof this.number === 'number'
  }

  hasColor(): boolean {
    return typeof this.color === 'number'
  }

  isAvailableAfter(prevCard: Card, cumulativeDraw: number, isLastCard: boolean): boolean {
    if (isLastCard && !this.isNumberCard()) {
      return false
    }
    if (cumulativeDraw > 0) {
      if ((prevCard.function === CardFunction.DRAW_2 || prevCard.function === CardFunction.WILD_DRAW_4) && this.function === CardFunction.WILD_DRAW_4) {
        return true
      }
      if (prevCard.function === CardFunction.DRAW_2 && this.function === CardFunction.DRAW_2 && this.color === prevCard.color) {
        return true
      }
    }
    if (prevCard.isNumberCard()) {
      if (this.number === prevCard.number || this.color === prevCard.color) {
        return true
      }
    } else {
      if (this.color === prevCard.color || this.function === prevCard.function) {
        return true
      }
    }

    return this.function === CardFunction.WILD || this.function === CardFunction.WILD_DRAW_4
  }

  copy(newID?: boolean): Card {
    const newCard = Card.fromObject(JSON.parse(JSON.stringify(this.toObject())))
    if (newID) {
      newCard.id = uuidV4()
    }
    return newCard
  }

  toObject(): object {
    const objectMapper = new ObjectMapper()
    return objectMapper.toObject(this)
  }

  static fromObject(obj: object): Card {
    const objectMapper = new ObjectMapper()
    return objectMapper.parse(obj, {mainCreator: () => [Card]})
  }
}

const cards: { item: Card, weight: number }[] = []

function initCards() {
  if (cards.length === 0) {
    for (let color = Color.GREEN; color <= Color.BLUE; color++) {
      cards.push({
        item: new Card(color, 0, null),
        weight: 1 / 108,
      })
      cards.push({
        item: new Card(color, null, CardFunction.SKIP),
        weight: 2 / 108,
      })
      cards.push({
        item: new Card(color, null, CardFunction.REVERSE),
        weight: 2 / 108,
      })
      cards.push({
        item: new Card(color, null, CardFunction.DRAW_2),
        weight: 2 / 108,
      })
      for (let number = 1; number <= 9; number++) {
        cards.push({
          item: new Card(color, number, null),
          weight: 2 / 108,
        })
      }
    }
    cards.push({
      item: new Card(null, null, CardFunction.WILD),
      weight: 4 / 108,
    })
    cards.push({
      item: new Card(null, null, CardFunction.WILD_DRAW_4),
      weight: 4 / 108,
    })
  }
}

// todo use new random method
export function getRandomCard(onlyNumber?: boolean): Card {
  initCards()
  let card = weightedRandom(cards)
  if (onlyNumber) {
    while (!card.isNumberCard()) {
      card = weightedRandom(cards)
    }
  }
  return card.copy(true)
}

export function getRandomCards(count: number): Card[] {
  const result: Card[] = []
  for (let i = 0; i < count; i++) {
    result.push(getRandomCard().copy(true))
  }
  return result
}

function weightedRandom<T>(options: { item: T, weight: number }[]): T {
  let i

  let weights: number[] = []

  for (i = 0; i < options.length; i++)
    weights[i] = options[i].weight + (weights[i - 1] || 0)

  let random = Math.random() * weights[weights.length - 1]

  for (i = 0; i < weights.length; i++)
    if (weights[i] > random)
      break

  return options[i].item
}

export function cardComparator(a: Card, b: Card): number {
  const getCardNum = (card: Card) => {
    let num = (card.number ?? 0) + (card.function !== null ? (card.function + 10) : 0) + (card.color ?? 0) * 20
    if (card.function === CardFunction.WILD_DRAW_4) {
      num = 100
    } else if (card.function === CardFunction.WILD) {
      num = 99
    }
    return num
  }

  const numA = getCardNum(a)
  const numB = getCardNum(b)
  if (numA === numB) {
    return a.id > b.id ? 1 : -1
  } else {
    return numA - numB
  }
}
