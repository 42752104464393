import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/analytics'
import 'firebase/app-check'
import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import GamePage from './gamePage/GamePage'
import HomePage from './homePage/HomePage'
import './index.scss'

const firebaseConfig = {
  apiKey: "AIzaSyDJU5q6P0UWdG52uzLzv0fn-ekPXDVrH9o",
  authDomain: "puno-401c2.firebaseapp.com",
  databaseURL: "https://puno-401c2-default-rtdb.firebaseio.com",
  projectId: "puno-401c2",
  storageBucket: "puno-401c2.appspot.com",
  messagingSenderId: "635421386842",
  appId: "1:635421386842:web:25a805006017492d569063",
  measurementId: "G-1XBXZEGYCB"
};


if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig)
  // firebase.appCheck().activate('6LdPBVobAAAAAGXr2ca4beJfiVcc2J3TyrJ4ehtv')
  firebase.analytics()
}

function App() {
  return <Router>
    <Switch>
      <Route exact path="/room/:roomID">
        <GamePage/>
      </Route>
      <Route exact path="/">
        <HomePage/>
      </Route>
      <Route path="*">
        <Redirect to="/"/>
      </Route>
    </Switch>
  </Router>

}


ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root'),
)
