import firebase from 'firebase/app'
import {useState} from 'react'
import {shortenUrl, uuidV4} from '../util/utils'
import {getAuthedUser} from '../util/auth'
import {useHistory} from 'react-router-dom'
import {GameState} from '../model/RoomData'
import classes from './homePage.module.scss'
import PButton from '../components/PButton'

export default function HomePage() {
  const [isCreating, setCreating] = useState(false)
  let history = useHistory()

  return <div className={classes.container}>
    <h1 className={classes.title}>Puno!</h1>
    <p className={classes.byPega}>By <a href="https://pegas.is" target="_blank">Pegasis</a></p>
    <PButton onClick={async () => {
      setCreating(true)
      const roomID = await createRoom()
      history.push(`/room/${roomID}`)
    }}>
      {isCreating ? 'Creating.....' : 'Create Room'}
    </PButton>
  </div>
}

async function createRoom() {
  let roomID = uuidV4()
  let shortUrl = await shortenUrl(`https://puno.pegas.is/room/${roomID}`)
  let user = await getAuthedUser()
  let database = firebase.database()
  let players = {}
  // @ts-ignore
  players[user.uid] = 0
  await database.ref(`rooms/${roomID}`).set({
    shortUrl,
    owner: user.uid,
    state: GameState.WAITING,
    players,
    lastActionTime: Date.now(),
  })
  return roomID
}
