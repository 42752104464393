import firebase from 'firebase/app'
import {errorAlert} from './utils'

export async function getAuthedUser(): Promise<firebase.User> {
  const auth = firebase.auth()
  if (auth.currentUser) {
    return auth.currentUser
  }
  try {
    const credential = await auth.signInAnonymously()
    if (credential.user) {
      return credential.user
    } else {
      throw new Error('user is null')
    }
  } catch (e) {
    errorAlert(e)
    throw e
  }
}
