import {useEffect, useState} from 'react'

export function errorAlert(e: unknown) {
  console.trace()
  console.error(e)
  alert(`Error occurred ${JSON.stringify(e)}`)
}

export function uuidV4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function shuffleArray<T>(array: T[]): T[] {
  let currentIndex = array.length, randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}

function fallbackCopyTextToClipboard(text: string) {
  let textArea = document.createElement('textarea')
  textArea.value = text

  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
  }

  document.body.removeChild(textArea)
}

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text)
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight])
  useEffect(() => {
    function handleResize() {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    // @ts-ignore
    (navigator.msMaxTouchPoints > 0))
}

export async function shortenUrl(link: string): Promise<string> {
  try {
    const controller = new AbortController()
    const res = await fetch('https://s.pegas.is/api/add', {
      signal: controller.signal,
      method: 'post',
      body: JSON.stringify({url: link}),
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
    })
    sleep(10000).then(() => controller.abort())
    const resData = await res.json()
    if (res.status === 200 && typeof resData['shortUrl'] === 'string') {
      return resData['shortUrl']
    } else {
      throw new Error(`res status ${res.status}, body: ${JSON.stringify(resData)}`)
    }
  } catch (e) {
    console.error(e)
    return link
  }
}