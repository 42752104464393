import {RoomData} from '../model/RoomData'
import QRCode from 'react-qr-code'
import classes from './qrCodeDialog.module.scss'
import PButton from '../components/PButton'
import PDialog from '../components/PDialog'
import React from 'react'

export default function QRCodeDialog(props: {
  roomData: RoomData,
  open: boolean,
  onClose: () => void,
}) {
  return <PDialog open={props.open} className={classes.dialog}>
    <div className={classes.dialogTitle}>
      Join the room at:
    </div>
    <div className={classes.link}>
      {props.roomData.shortUrl ?? window.location.href}
    </div>
    <div  className={classes.dialogTitle}>
      Or scan the following QR code:
    </div>
    <QRCode value={props.roomData.shortUrl ?? window.location.href} className={classes.qrCode}/>
    <div className={classes.dialogButtonBar}>
      <PButton onClick={props.onClose}>
        Close
      </PButton>
    </div>
  </PDialog>
}