import React from 'react'
import classes from './PDialog.module.scss'
import {AnimatePresence, motion} from 'framer-motion'

export default function PDialog(props: { children: React.ReactNode, className?: string, open: boolean }) {
  return <AnimatePresence>
    {props.open ? <motion.div
      key="modal"
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      className={classes.dialogBackground}
      onClick={e => e.stopPropagation()}>
      <motion.div
        initial={{transform: 'scale(0)'}}
        animate={{transform: 'scale(1)'}}
        exit={{transform: 'scale(0)'}}
        className={classes.dialog + ' ' + props.className}>
        {props.children}
      </motion.div>
    </motion.div> : null}
  </AnimatePresence>
}
