import {Card, cardComparator, CardFunction, getRandomCards} from './Card'
import {JsonClassType, JsonIgnore, JsonProperty, ObjectMapper} from 'jackson-js'
import {cardDeckCardCount} from '../gamePage/WaitingFragment'

export enum GameState {
  WAITING,
  RUNNING,
  WINNER_ANIMATION,
}

export class RoomData {
  @JsonProperty() @JsonClassType({type: () => [String]})
  shortUrl: string | null = null

  @JsonProperty() @JsonClassType({type: () => [String]})
  owner: string = ''

  @JsonProperty() @JsonClassType({type: () => [Map, [String, Number]]})
  players: Map<string, number> = new Map<string, number>()

  @JsonProperty() @JsonClassType({type: () => [Map, [String, Number]]})
  kickedPlayers: Map<string, number> = new Map<string, number>()

  @JsonProperty() @JsonClassType({type: () => [String]})
  lastWinner: string | null = null

  @JsonProperty() @JsonClassType({type: () => [Number]})
  state: GameState = GameState.WAITING

  @JsonProperty() @JsonClassType({type: () => [Array, [Number]]})
  playerOrder: string[] = []

  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  isForward: boolean = true

  @JsonProperty() @JsonClassType({type: () => [Number]})
  currentPlayerI: number = 0

  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  currentPlayerDrew: boolean = false

  @JsonProperty() @JsonClassType({type: () => [Number]})
  cumulativeDraw: number = 0

  @JsonProperty() @JsonClassType({type: () => [Card]})
  lastCardOnDesk: Card | null = null

  @JsonProperty() @JsonClassType({type: () => [Card]})
  cardOnDesk: Card | null = null

  @JsonProperty() @JsonClassType({type: () => [Map, [String, [Array, [Card]]]]})
  cards: Map<string, Card[]> = new Map<string, Card[]>()

  @JsonProperty() @JsonClassType({type: () => [Array, [Card]]})
  cardDeckCards: Card[] | null = null

  @JsonProperty() @JsonClassType({type: () => [Number]})
  lastActionTime: number = Date.now()

  @JsonIgnore()
  get currentPlayer() {
    return this.playerOrder[this.currentPlayerI]
  }

  copy(): RoomData {
    return RoomData.fromObject(JSON.parse(JSON.stringify(this.toObject())))
  }

  toObject(): object {
    const objectMapper = new ObjectMapper()
    return objectMapper.toObject(this)
  }

  drawCardData(): RoomData {
    const newRoomData = this.copy()

    const myCards = newRoomData.cards.get(this.playerOrder[newRoomData.currentPlayerI])!
    const drawCount = Math.max(this.cumulativeDraw, 1)

    myCards.push(...newRoomData.cardDeckCards!.splice(0, Math.min(cardDeckCardCount, drawCount)))
    myCards.push(...(getRandomCards(Math.max(0, drawCount - cardDeckCardCount))))
    myCards.sort(cardComparator)
    newRoomData.cardDeckCards!.push(...(getRandomCards(cardDeckCardCount - newRoomData.cardDeckCards!.length)))

    newRoomData.currentPlayerDrew = true
    newRoomData.cumulativeDraw = 0
    return newRoomData
  }

  nextRoundData(newCard: Card | null): RoomData {
    const newRoomData = this.copy()
    newRoomData.lastActionTime = Date.now()

    const nextPlayer = () => {
      if (newRoomData.isForward) {
        newRoomData.currentPlayerI++
        if (newRoomData.currentPlayerI >= newRoomData.playerOrder.length) {
          newRoomData.currentPlayerI = 0
        }
      } else {
        newRoomData.currentPlayerI--
        if (newRoomData.currentPlayerI < 0) {
          newRoomData.currentPlayerI = newRoomData.playerOrder.length - 1
        }
      }
    }

    newRoomData.currentPlayerDrew = false
    if (newCard) {
      newRoomData.lastCardOnDesk = newRoomData.cardOnDesk
      newRoomData.cardOnDesk = newCard
      const myCards = newRoomData.cards.get(this.currentPlayer)!
      myCards.splice(myCards.findIndex(({id}) => id === newCard.id), 1)

      if (newRoomData.cumulativeDraw > 0 && newCard.function !== CardFunction.DRAW_2 && newCard.function !== CardFunction.WILD_DRAW_4) {
        myCards.push(...newRoomData.cardDeckCards!.splice(0, Math.min(cardDeckCardCount, newRoomData.cumulativeDraw)))
        myCards.push(...(getRandomCards(Math.max(0, newRoomData.cumulativeDraw - cardDeckCardCount))))
        myCards.sort(cardComparator)
        newRoomData.cardDeckCards!.push(...(getRandomCards(cardDeckCardCount - newRoomData.cardDeckCards!.length)))
        newRoomData.cumulativeDraw = 0
      }

      if (newCard.function === CardFunction.SKIP) {
        nextPlayer()
      } else if (newCard.function === CardFunction.REVERSE) {
        newRoomData.isForward = !newRoomData.isForward
      } else if (newCard.function === CardFunction.DRAW_2) {
        newRoomData.cumulativeDraw += 2
      } else if (newCard.function === CardFunction.WILD_DRAW_4) {
        newRoomData.cumulativeDraw += 4
      } else if (newCard.function === CardFunction.WILD) {
        // do nothing
      }

      if (myCards.length <= 0) {
        newRoomData.lastWinner = this.currentPlayer
        newRoomData.state = GameState.WINNER_ANIMATION
      }
    }

    nextPlayer()
    return newRoomData
  }

  static fromObject(obj: object): RoomData {
    const objectMapper = new ObjectMapper()
    return objectMapper.parse(obj, {mainCreator: () => [RoomData]})
  }
}
