import {Redirect, useHistory, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import firebase from 'firebase/app'
import {getAuthedUser} from '../util/auth'
import WaitingFragment from './WaitingFragment'
import {useAuthState} from 'react-firebase-hooks/auth'
import InGameFragment from './InGameFragment'
import {GameState, RoomData} from '../model/RoomData'
import {getRandomCards} from '../model/Card'
import PTextField from '../components/PTextField'
import classes from './GamePage.module.scss'

export default function GamePage() {
  // @ts-ignore
  let {roomID} = useParams()
  let history = useHistory()
  let [roomData, setRoomData] = useState<RoomData | null>(null)
  const [user] = useAuthState(firebase.auth())

  useEffect(() => {
    let dataListener: ((snapshot: firebase.database.DataSnapshot) => void) | null = null
    const roomRefContainer: any[] = [null];

    (async () => {
      const user = await getAuthedUser()
      const database = firebase.database()
      const roomRef = database.ref(`rooms/${roomID}/`)
      roomRefContainer[0] = roomRef
      const roomSnapshot = await database.ref(`rooms/${roomID}/`).get()
      if (!roomSnapshot.exists()) {
        history.push('/')
      } else {
        const roomData = RoomData.fromObject(roomSnapshot.val())
        if (roomData.kickedPlayers.has(user.uid)) {
          alert('You have been kicked from this room.')
          history.push('/')
        } else {
          if (roomData.state === GameState.WAITING) {
            await database.ref(`rooms/${roomID}/players/${user.uid}`).set(0)
          } else if (!roomData.playerOrder.includes(user.uid)) {
            await database.ref(`rooms/${roomID}`).update(Object.fromEntries([
              [`cards/${user.uid}`, getRandomCards(7)],
              [`players/${user.uid}`, 0],
              [`playerOrder/${roomData.playerOrder.length}`, user.uid],
            ]))
          }

          dataListener = (snapshot) => {
            setRoomData(RoomData.fromObject(snapshot.val()))
          }
          dataListener = roomRef.on('value', dataListener)
        }
      }
    })()

    return () => {
      if (dataListener) {
        roomRefContainer[0]?.off('value', dataListener)
      }
    }
  }, [])

  useEffect(() => {
    if (roomData && user && roomData.kickedPlayers.has(user.uid)) {
      alert('You have been kicked from this room.')
    }
  }, [roomData && user && roomData.kickedPlayers.has(user.uid)])

  if (roomData && user && roomData.kickedPlayers.has(user.uid)) {
    return <Redirect to="/"/>
  } else if (roomData === null || !user || (roomData.state === GameState.RUNNING && !roomData.playerOrder.includes(user.uid))) {
    return <div className={classes.loadingText}>
      Loading.....
    </div>
  } else if (roomData.state === GameState.WAITING) {
    return <WaitingFragment roomData={roomData} roomID={roomID} user={user}/>
  } else {
    return <InGameFragment roomData={roomData} roomID={roomID} user={user}/>
  }
}

export function UserName(props: { uid: string, disableEdit?: boolean, roomOwner?: string, className?: string }) {
  const [displayName, setDisplayName] = useState('')
  const [isCurrentUser, setIsCurrentUser] = useState(false)
  const userNameRef = firebase.database().ref(`users/${props.uid}/`)

  useEffect(() => {
    let userNameListener: ((snapshot: firebase.database.DataSnapshot) => void) | null = null;
    (async () => {
      const user = await getAuthedUser()
      if (user.uid === props.uid && !props.disableEdit) {
        setIsCurrentUser(true)
        const displayNameSnapshot = await userNameRef.get()
        if (displayNameSnapshot.exists()) {
          setDisplayName(displayNameSnapshot.val())
        } else {
          setDisplayName(props.uid)
        }
      } else {
        userNameListener = (snapshot) => {
          if (snapshot.exists()) {
            setDisplayName(snapshot.val())
          } else {
            setDisplayName(props.uid)
          }
        }
        userNameRef.on('value', userNameListener)
      }
    })()

    return () => {
      if (userNameListener) {
        userNameRef.off('value')
      }
    }
  }, [])

  if (isCurrentUser) {
    return <span>
      <PTextField
        className={props.className}
        value={displayName}
        onChange={newName => {
          setDisplayName(newName)
          userNameRef.set(newName)
        }}
      />
    </span>
  } else {
    return <span className={props.className}>
      {(displayName === '' ? '(Empty Name)' : displayName) + (props.uid === props.roomOwner ? ' (Host)' : '')}
    </span>
  }
}

