import firebase from 'firebase/app'
import {GameState, RoomData} from '../model/RoomData'
import React, {useEffect, useMemo, useState} from 'react'
import {UserName} from './GamePage'
import {Card, cardColorMap, CardFunction, Color} from '../model/Card'
import {copyTextToClipboard, isTouchDevice, sleep, useWindowSize} from '../util/utils'
import {useHistory} from 'react-router-dom'
import {CardElem, cardHeight, cardWidth} from './CardElem'
import classes from './inGameFragment.module.scss'
import PButton from '../components/PButton'
import CardBackStack from './CardBackStack'
import {BezierCurve, Point} from '../util/BezierCurve'
import rotateForwards from './rotate_forwards.svg'
import rotateBackwards from './rotate_backwards.svg'
import PDialog from '../components/PDialog'
import {AnimateSharedLayout, motion} from 'framer-motion'
import QRCodeDialog from './QRCodeDialog'

type InGameFragmentProps = {
  roomID: string,
  roomData: RoomData,
  user: firebase.User,
}

export default function InGameFragment({roomID, roomData, user}: InGameFragmentProps) {
  const [isQROpen, setIsQROpen] = useState(false)
  const [choosingColorCard, setChoosingColorCard] = useState<Card | null>(null)
  const [isExitingRoom, setIsExitingRoom] = useState(false)
  const history = useHistory()
  const [windowW, windowH] = useWindowSize()
  const [selectedCard, setSelectedCard] = useState<Card | null>(null)
  const playerPoints = useMemo(() => {
    const curve = new BezierCurve([
      new Point(100, windowH / 2),
      new Point(100, 0),
      new Point(windowW - 100, 0),
      new Point(windowW - 100, windowH / 2),
    ])
    const points = []
    const spacing = 1 / roomData.players.size
    for (let i = 0; i < roomData.players.size - 1; i++) {
      points.push(curve.calc(spacing * (i + 1)))
    }
    return points
  }, [windowW, windowH, roomData.players.size])
  const [isFirstRender, setIsFirstRender] = useState(true)
  useEffect(() => setIsFirstRender(false), [])
  const [showChangeNameDialog, setShowChangeNameDialog] = useState(false)
  useEffect(() => {
    firebase.database().ref(`users/${user.uid}/`).get().then(snapshot => {
      if (!snapshot.exists()) {
        setShowChangeNameDialog(true)
      }
    })
  }, [])

  const drawCards = () => {
    firebase.database().ref(`rooms/${roomID}`).set(roomData.drawCardData().toObject())
  }
  const playCard = (card: Card) => {
    if (card.function === CardFunction.WILD || card.function === CardFunction.WILD_DRAW_4) {
      setChoosingColorCard(card)
    } else {
      let newRoomData = roomData.nextRoundData(card)
      firebase.database().ref(`rooms/${roomID}`).set(newRoomData.toObject())
      if (newRoomData.state === GameState.WINNER_ANIMATION) {
        newRoomData = newRoomData.copy()
        newRoomData.state = GameState.WAITING
        sleep(2000).then(() => firebase.database().ref(`rooms/${roomID}`).set(newRoomData.toObject()))
      }
    }
  }

  const cardDeckDivs: React.ReactElement[] = []
  for (let i = roomData.cardDeckCards!.length - 1; i >= 0; i--) {
    const card = roomData.cardDeckCards![i]
    cardDeckDivs.push(<CardElem key={card.id} back card={card} className={classes.cardDeckCard} delay={i * 0.2}/>)
  }

  const otherPlayerDivs: React.ReactElement[] = []
  const playerStartI = roomData.playerOrder.indexOf(user.uid) + 1
  for (let i = playerStartI; i < playerStartI + roomData.playerOrder.length - 1; i++) {
    const uid = roomData.playerOrder[i % roomData.playerOrder.length]
    otherPlayerDivs.push(<PlayerNameCard
      pos={playerPoints[i - playerStartI]}
      key={uid}
      roomID={roomID}
      roomData={roomData}
      user={user}
      uid={uid}/>)
  }
  const isYourRound = roomData.currentPlayer === user.uid && roomData.state === GameState.RUNNING
  return <AnimateSharedLayout>
    <div>
      {roomData.lastCardOnDesk ?
        <CardOnDesk animate={false} key={roomData.lastCardOnDesk!.id} card={roomData.lastCardOnDesk!}/> : null}
      <CardOnDesk animate={!isFirstRender} key={roomData.cardOnDesk!.id} card={roomData.cardOnDesk!}/>

      <div className={classes.directionIndicator}>
        {isYourRound ? <div className={classes.yourRoundText}>Your round!</div> : null}
        <img
          src={roomData.isForward ? rotateForwards : rotateBackwards}
          className={roomData.isForward ? classes.rotateForwards : classes.rotateBackwards}
          width="60px"/>
        {roomData.cumulativeDraw > 0 ?
          <div className={classes.cumulativeDrawText}>+{roomData.cumulativeDraw}</div> : null}
      </div>

      <div className={classes.bottomPanel}>
        {isYourRound ? <div style={{marginBottom: isTouchDevice() ? '48px' : '12px'}}>
          {roomData.currentPlayerDrew ?
            <PButton className={classes.yourRoundButton}
                     onClick={() => {
                       firebase.database().ref(`rooms/${roomID}`).set(roomData.nextRoundData(null).toObject())
                     }}>Skip me</PButton> :
            <PButton className={classes.yourRoundButton}
                     onClick={drawCards}>Draw {Math.max(roomData.cumulativeDraw, 1)} card</PButton>}
          {isTouchDevice() ?
            <PButton disabled={selectedCard === null || (roomData.cards.get(user.uid)?.includes(selectedCard) !== true)}
                     className={classes.yourRoundButton} onClick={() => playCard(selectedCard!)}>Play</PButton> : null}

        </div> : null}
        <CardRow roomData={roomData} user={user} isYourRound={isYourRound}
                 roomID={roomID} selectedCard={selectedCard} setSelectedCard={setSelectedCard} playCard={playCard}/>
      </div>

      {otherPlayerDivs}

      <div
        className={classes.cardDeck + ' ' + ((isYourRound && !roomData.currentPlayerDrew) ? classes.available : '')}
        style={{height: cardHeight + 'px', width: cardWidth + 'px'}}
        onClick={(roomData.currentPlayerDrew || !isYourRound) ? undefined : drawCards}>
        {cardDeckDivs}
      </div>

      <QRCodeDialog roomData={roomData} open={isQROpen} onClose={() => setIsQROpen(false)}/>
      <div className={classes.topBar}>
        <PButton onClick={() => {
          copyTextToClipboard(roomData.shortUrl ?? window.location.href)
          setIsQROpen(true)
        }}> Copy Room Link / QR Code</PButton>
        <PButton onClick={async () => {
          setIsExitingRoom(true)
          const newRoomData = roomData.copy()
          newRoomData.players.delete(user.uid)
          newRoomData.cards.delete(user.uid)
          newRoomData.playerOrder.splice(newRoomData.playerOrder.indexOf(user.uid), 1)
          if (newRoomData.currentPlayerI >= newRoomData.playerOrder.length) {
            newRoomData.currentPlayerI--
          }
          if (newRoomData.playerOrder.length === 1) {
            newRoomData.state = GameState.WAITING
            newRoomData.lastWinner = null
          }
          if (newRoomData.owner === user.uid) {
            newRoomData.owner = Array.from(newRoomData.players.keys())[0]
          }
          firebase.database().ref(`rooms/${roomID}`).set(newRoomData.toObject())
          history.push('/')
        }}>{isExitingRoom ? 'Exiting.....' : 'Exit Room'}</PButton>
      </div>

      <PDialog open={!!choosingColorCard} className={classes.dialog}>
        <div className={classes.dialogTitle}>
          Choose a Color:
        </div>
        <div className={classes.chooseColorRow}>
          <ChooseColorBlock color={Color.BLUE} choosingColorCard={choosingColorCard}
                            setChoosingColorCard={setChoosingColorCard} roomID={roomID} roomData={roomData}/>
          <ChooseColorBlock color={Color.GREEN} choosingColorCard={choosingColorCard}
                            setChoosingColorCard={setChoosingColorCard} roomID={roomID} roomData={roomData}/>
        </div>
        <div className={classes.chooseColorRow}>
          <ChooseColorBlock color={Color.RED} choosingColorCard={choosingColorCard}
                            setChoosingColorCard={setChoosingColorCard} roomID={roomID} roomData={roomData}/>
          <ChooseColorBlock color={Color.YELLOW} choosingColorCard={choosingColorCard}
                            setChoosingColorCard={setChoosingColorCard} roomID={roomID} roomData={roomData}/>
        </div>
        <div className={classes.dialogButtonBar}>
          <PButton onClick={() => setChoosingColorCard(null)}>
            Cancel
          </PButton>
        </div>
      </PDialog>
      <PDialog open={showChangeNameDialog} className={classes.dialog + ' ' + classes.nameDialog}>
        <div className={classes.dialogTitle}>
          Your Name:
        </div>
        <UserName uid={user.uid}/>
        <div className={classes.dialogButtonBar}>
          <PButton onClick={() => setShowChangeNameDialog(false)}>
            OK
          </PButton>
        </div>
      </PDialog>
    </div>
  </AnimateSharedLayout>
}

function CardRow(
  {
    roomData,
    user,
    isYourRound,
    selectedCard,
    setSelectedCard,
    playCard,
  }: {
    roomData: RoomData,
    user: firebase.User,
    isYourRound: boolean,
    roomID: string,
    selectedCard: Card | null,
    setSelectedCard: (card: Card | null) => void,
    playCard: (card: Card) => void,
  }) {
  const [windowW] = useWindowSize()
  const cards = roomData.cards.get(user.uid) || []
  const scale = 1.4
  const cardSpacing = Math.min(cardWidth * scale + 4, (windowW - (cardWidth * scale + 4) - 32) / cards.length)
  return <div className={classes.myCardRow + ' ' + (isYourRound ? classes.available : '')}>
    {cards.map((card, i) => <motion.div
      initial={false}
      key={card.id}
      animate={{
        width: (i === cards.length - 1) ? ((cardWidth * scale + 4) + 'px') : (cardSpacing + 'px'),
      }}>
      <CardElem
        selected={isTouchDevice() && (selectedCard === card)}
        zIndex={500 + i * 2}
        scale={1.4}
        card={card}
        isAvailable={isYourRound && card.isAvailableAfter(roomData.cardOnDesk!, roomData.cumulativeDraw, cards.length === 1)}
        onClick={() => {
          if (isTouchDevice()) {
            if (selectedCard !== card) {
              setSelectedCard(card)
            } else {
              setSelectedCard(null)
            }
          } else {
            playCard(card)
          }
        }}/>
    </motion.div>)}
  </div>
}

function CardOnDesk({card, animate}: { card: Card, animate: boolean }) {
  const [scale, setScale] = useState(animate ? 3 : 2)
  useEffect(() => {
    if (animate) sleep(500).then(() => setScale(2))
  }, [])

  return <div className={classes.cardOnDeskWrapper}>
    <CardElem card={card} scale={scale}/>
  </div>
}

function PlayerNameCard({roomID, roomData, user, uid, pos}: InGameFragmentProps & { uid: string, pos: Point }) {
  const isCurrentPlayer = uid === roomData.currentPlayer && roomData.state === GameState.RUNNING
  return <motion.div
    key={uid}
    className={classes.playerNameCardWrapper}
    initial={false}
    animate={{
      top: pos.y + 'px',
      left: pos.x + 'px',
    }}>
    <CardBackStack cards={roomData.cards.get(uid) || []} glow={isCurrentPlayer}/>
    <div className={classes.playerNameLine}>
      <UserName uid={uid} roomOwner={roomData.owner} className={isCurrentPlayer ? classes.currentPlayerUserName : ''}/>
      <KickPlayerButton roomID={roomID} roomData={roomData} user={user} uid={uid}/>
    </div>
  </motion.div>
}

function ChooseColorBlock(props: { color: Color, choosingColorCard: Card | null, setChoosingColorCard: Function, roomID: string, roomData: RoomData }) {
  return <div key={props.color} style={{backgroundColor: cardColorMap.get(props.color)!.color}} onClick={() => {
    if (props.choosingColorCard) {
      props.choosingColorCard!.color = props.color
      props.setChoosingColorCard(null)
      firebase.database().ref(`rooms/${props.roomID}`).set(props.roomData.nextRoundData(props.choosingColorCard).toObject())
    }
  }}/>
}

function KickPlayerButton({roomID, roomData, user, uid}: InGameFragmentProps & { uid: string }) {
  return (user.uid === roomData.owner) ? <PButton
    compact
    className={classes.kickButton}
    onClick={() => {
      const newRoomData = roomData.copy()
      newRoomData.kickedPlayers.set(uid, 0)
      newRoomData.players.delete(uid)
      newRoomData.cards.delete(uid)
      newRoomData.playerOrder.splice(newRoomData.playerOrder.indexOf(uid), 1)
      if (newRoomData.currentPlayerI >= newRoomData.playerOrder.length) {
        newRoomData.currentPlayerI--
      }
      if (newRoomData.playerOrder.length === 1) {
        newRoomData.state = GameState.WAITING
        newRoomData.lastWinner = null
      }
      firebase.database().ref(`rooms/${roomID}`).set(newRoomData.toObject())
    }}>Kick</PButton> : null
}
