import classes from './cardBackStack.module.scss'
import {CardElem, cardHeight, cardWidth} from './CardElem'
import React from 'react'
import {Card} from '../model/Card'
import {motion} from 'framer-motion'

const maxWidth = 200 - cardWidth

export default function CardBackStack(props: { cards: Card[], glow?: boolean }) {
  const cardSpacing = Math.min(12, maxWidth / props.cards.length)

  return <motion.div
    className={classes.container + ' ' + (props.glow ? classes.glow : '')}
    style={{
      paddingBottom: cardHeight + 'px',
      paddingRight: (cardWidth - cardSpacing) + 'px',
    }}
    animate={{
      width: Math.min(12 * props.cards.length, maxWidth) + 'px',
    }}
    initial={false}>
    {props.cards.map((card) => <div
      key={card.id}
      style={{width: cardSpacing + 'px'}}>
      <CardElem back card={card} className={classes.cardBack}/>
    </div>)}
  </motion.div>
}
