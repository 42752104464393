import React from 'react'
import classes from './PButton.module.scss'

export default function PButton(props: {
  onClick?: () => void,
  disabled?: boolean,
  children: React.ReactNode,
  className?: string,
  compact?: boolean,
}) {
  return <button onClick={props.onClick}
                 disabled={props.disabled}
                 className={classes.button + ' ' + (props.compact ? classes.compact : '') + ' ' + props.className}>
    {props.children}
  </button>
}
