export class BezierCurve {
  constructor(
    public points: Point[],
  ) {
  }

  calc(t: number): Point {
    if (this.points.length === 2) {
      return this.points[0].middle(this.points[1], t)
    } else {
      const newCurve = new BezierCurve([])
      for (let i = 0; i < this.points.length - 1; i++) {
        newCurve.points.push(this.points[i].middle(this.points[i + 1], t))
      }
      return newCurve.calc(t)
    }
  }
}

export class Point {
  constructor(
    public x: number,
    public y: number,
  ) {
  }

  middle(other: Point, t: number): Point {
    return new Point((1 - t) * this.x + t * other.x, (1 - t) * this.y + t * other.y)
  }
}
