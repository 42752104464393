import firebase from 'firebase/app'
import React, {useState} from 'react'
import {UserName} from './GamePage'
import {Card, cardComparator, getRandomCard, getRandomCards} from '../model/Card'
import {copyTextToClipboard, shuffleArray} from '../util/utils'
import {GameState, RoomData} from '../model/RoomData'
import classes from './waitingFragment.module.scss'
import PButton from '../components/PButton'
import {useHistory} from 'react-router-dom'
import QRCodeDialog from './QRCodeDialog'

type WaitingFragmentProps = {
  roomID: string,
  roomData: RoomData,
  user: firebase.User,
}

export const cardDeckCardCount = 16

export default function WaitingFragment(props: WaitingFragmentProps) {
  const [isExitingRoom, setIsExitingRoom] = useState(false)
  const [isStarting, setIsStarting] = useState(false)
  const [isQROpen, setIsQROpen] = useState(false)
  const history = useHistory()

  const startGame = async () => {
    setIsStarting(true)
    const newRoomData = props.roomData.copy()
    newRoomData.state = GameState.RUNNING
    newRoomData.cards = new Map<string, Card[]>(Array.from(props.roomData.players.keys(), uid => [uid, getRandomCards(7).sort(cardComparator)]))
    newRoomData.isForward = true
    newRoomData.playerOrder = shuffleArray(Array.from(props.roomData.players.keys()))
    newRoomData.currentPlayerI = 0
    newRoomData.cardOnDesk = getRandomCard(true)
    newRoomData.cumulativeDraw = 0
    newRoomData.currentPlayerDrew = false
    newRoomData.cardDeckCards = getRandomCards(cardDeckCardCount)
    await firebase.database().ref(`rooms/${props.roomID}`).set(newRoomData.toObject())
  }

  return <div>
    {props.roomData.lastWinner ? <div className={classes.topPanel}>
      <div className={classes.topPanelTitle}>Winner from last round:</div>
      <UserName uid={props.roomData.lastWinner} disableEdit={true}/>
    </div> : null}
    <div className={classes.topPanel}>
      <div className={classes.topPanelTitle}>Joined Players:</div>
      {Array.from(props.roomData.players.keys(), uid => <div key={uid}>
        <UserName uid={uid} roomOwner={props.roomData.owner} disableEdit/>
      </div>)}
    </div>

    <div className={classes.bottomPanel}>
      <div>
        <span>Your Name: </span>
        <UserName uid={props.user.uid}/>
      </div>
      {props.roomData.owner !== props.user.uid ? <div className={classes.waitingForHost}>
        Waiting for the host to start the game.....
      </div> : null}
      <div className={classes.buttonBar}>
        <PButton className={classes.bottomButtons}
                 onClick={() => {
                   setIsExitingRoom(true)
                   const newRoomData = props.roomData.copy()
                   newRoomData.players.delete(props.user.uid)
                   if (newRoomData.players.size === 0) {
                     firebase.database().ref(`rooms/${props.roomID}`).remove()
                   } else {
                     if (newRoomData.owner === props.user.uid) {
                       newRoomData.owner = Array.from(newRoomData.players.keys())[0]
                     }
                     firebase.database().ref(`rooms/${props.roomID}`).set(newRoomData.toObject())
                   }
                   history.push('/')
                 }}>
          {isExitingRoom ? 'Exiting.....' : 'Exit Room'}
        </PButton>
        <PButton className={classes.bottomButtons}
                 onClick={() => {
                   copyTextToClipboard(props.roomData.shortUrl ?? window.location.href)
                   setIsQROpen(true)
                 }}>
          Copy Room Link / QR Code
        </PButton>
        <QRCodeDialog roomData={props.roomData} open={isQROpen} onClose={() => setIsQROpen(false)}/>
        {props.user?.uid === props.roomData.owner ?
          <PButton className={classes.bottomButtons}
                   disabled={props.roomData.players.size < 2}
                   onClick={startGame}>
            {isStarting ? 'Starting' : 'Start Game'}
          </PButton> : null}
      </div>
    </div>
  </div>
}
