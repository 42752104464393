import {Card, cardColorMap, CardFunction} from '../model/Card'
import React, {useState} from 'react'
import classes from './cardElem.module.scss'
import {motion} from 'framer-motion'

export const cardWidth = 60
export const cardHeight = cardWidth / 242 * 362

export function CardElem(
  {
    selected,
    back,
    card,
    isAvailable,
    onClick,
    className,
    scale,
    delay,
    zIndex,
  }: {
    selected?: boolean,
    back?: boolean
    card: Card | null,
    isAvailable?: boolean,
    onClick?: () => void,
    className?: string,
    scale?: number,
    delay?: number,
    zIndex?: number,
  }) {
  const [isHovering, setIsHovering] = useState(false)
  if (!isAvailable) {
    onClick = undefined
  }
  let cardFileName = `/cards/back.svg`
  if (!back && card) {
    cardFileName = `/cards/${card.hasColor() ? (cardColorMap.get(card.color!)!.name.toLowerCase() + '_') : ''}`
    if (card.isNumberCard()) {
      cardFileName += `${card.number}.svg`
    } else if (card.function === CardFunction.SKIP) {
      cardFileName += `skip.svg`
    } else if (card.function === CardFunction.REVERSE) {
      cardFileName += `reverse.svg`
    } else if (card.function === CardFunction.DRAW_2) {
      cardFileName += `draw_2.svg`
    } else if (card.function === CardFunction.WILD) {
      cardFileName += `wild.svg`
    } else if (card.function === CardFunction.WILD_DRAW_4) {
      cardFileName += `wild_draw_4.svg`
    }
  }

  return <motion.div
    layoutId={card?.id}
    initial={false}
    className={classes.wrapper + ' ' + (onClick ? classes.available : '') + ' ' + className}
    onClick={onClick}
    style={{zIndex}}
    transition={{
      type: 'spring',
      duration: 0.4,
      bounce: 0.2,
    }}
    onHoverStart={onClick ? (() => setIsHovering(true)) : undefined}
    onHoverEnd={onClick ? (() => setIsHovering(false)) : undefined}>
    <motion.img
      src={cardFileName}
      initial={false}
      style={{
        zIndex: zIndex ? (zIndex + 1) : undefined,
      }}
      animate={{
        y: (selected || (isHovering && onClick)) ? '-30%' : undefined,
        boxShadow: (selected || (isHovering && onClick)) ? '0 0 10px 0 #FFFFFF' : undefined,
        width: cardWidth * (scale || 1) + 'px',
        borderRadius: (0.167 * cardWidth * (scale || 1)) + 'px',
      }}
      className={classes.card + ' ' + (onClick ? classes.available : '')}/>
  </motion.div>
}
